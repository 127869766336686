.pagetop4 {
    background-image: url(https://jiliko.ph/resource/images/pagetop4.jpg)
}

.img-hidden{
    display:none;
    visibility:hidden;
}
.casino-bg {
    background-image: url(https://jiliko.ph/resource/images/casino-bg.jpg);
}