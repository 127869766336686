@import 'https://fonts.googleapis.com/css2?family=Anton&display=swap';

body {
    padding: 0;
    overflow-x: hidden;
    font-size: 16px;
    color: #444;
    line-height: 1.5
}

.container {
    width: 1170px;
    margin: 0 auto
}

img,
a,
p,
ul,
li,
div,
input,
h1,
h2,
h3,
h4,
h5,
h6,
span,
textarea,
table,
tr,
td {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    margin: 0;
    padding: 0
}

body {
    font-family: open sans, Arial, Helvetica, sans-serif
}

a {
    text-decoration: none;
    color: #444
}

select,
textarea,
input,
a:hover,
a:focus,
button {
    outline: none
}

.img_fit {
    max-width: 100%;
    display: block
}

ul {
    padding-left: 0;
    list-style-type: none
}

body,
p,
ul {
    margin: 0
}

.clr {
    clear: both
}

.scroll_fix {
    overflow-y: hidden
}

table {
    border-collapse: collapse;
    width: 100%
}

.flex {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-wrap: wrap
}

.flex-md {
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center
}

.flex-justify {
    -webkit-justify-content: center;
    justify-content: center
}

.flex-bt {
    -webkit-align-items: flex-end;
    align-items: flex-end
}

.flex-space-between {
    justify-content: space-between
}

.flex-md-row-reverse {
    flex-direction: row-reverse
}

.flex-container {
    align-content: stretch
}

.flex-1 {
    flex: 1 0 0%
}

.col-auto {
    flex: 0 0 auto;
    width: auto
}

.mr-l {
    margin-left: auto
}

.col-6 {
    width: 50%
}

.col-3 {
    width: 33.33%
}

.col-9 {
    width: 66.66%
}

.col-4 {
    width: 25%
}

.col-8 {
    width: 75%
}

.col-45 {
    width: 45%
}

.col-55 {
    width: 55%
}

.col-1 {
    width: 16.66%
}

.col-5 {
    width: 20%
}

.col-80 {
    width: 80%
}

.col-12 {
    width: 100%
}

.col-10 {
    width: 90%
}

.col-R {
    padding-left: 20px
}

.col-L {
    padding-right: 20px
}

a,
.mask,
li:hover,
.btn,
nav li>a:after,
.thumbimg img,
.block {
    transition: all .3s ease;
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease
}

.thumbimg:hover img.col-12,
.btn:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
}

.row {
    margin: 0 -10px
}

.row>li {
    padding: 10px
}

hr {
    border: 0;
    border-top: 1px solid #bfbfbf;
    background: 0 0
}

.center-img {
    margin: 0 auto
}

header {
    background: -moz-linear-gradient(top, rgba(0, 109, 174, 0.6) 0%, rgba(0, 109, 174, 0.6) 52%, rgba(0, 109, 174, 0.4) 100%);
    background: -webkit-linear-gradient(top, rgba(0, 109, 174, 0.6) 0%, rgba(0, 109, 174, 0.6) 52%, rgba(0, 109, 174, 0.4) 100%);
    background: linear-gradient(to bottom, rgba(0, 109, 174, 0.6) 0%, rgba(0, 109, 174, 0.6) 52%, rgba(0, 109, 174, 0.4) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#99006dae', endColorstr='#66006dae', GradientType=0);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    text-align: center
}

.logo {
    display: inline-block;
    padding: 5px 0;
    width: 200px;
    vertical-align: middle
}

.topnav li>a {
    padding: 10px 15px;
    position: relative;
    display: block;
    color: var(--white)
}

.topnav li>a:hover {
    color: var(--primary-color)
}

.topnav,
.topnav li>a {
    height: 100%
}

.topnav li.active>a,
.topnav li.active>a:hover {
    border-bottom: 3px solid var(--primary-color);
    color: var(--primary-color)
}

#nav-icon {
    z-index: 1000;
    position: absolute;
    right: 0;
    top: 0;
    height: 50px;
    width: 50px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer
}

#nav-icon span {
    height: 2px;
    width: 80%;
    border-radius: 9px;
    opacity: 1;
    left: 10%;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out
}

#nav-icon span:nth-child(1) {
    top: 15px
}

#nav-icon span:nth-child(2) {
    top: 25px
}

#nav-icon span:nth-child(3) {
    top: 35px
}

#nav-icon.open span:nth-child(1) {
    top: 25px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg)
}

#nav-icon.open span:nth-child(2) {
    opacity: 0;
    left: -60px
}

#nav-icon.open span:nth-child(3) {
    top: 25px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg)
}

.down {
    left: -30px;
    right: -30px;
    z-index: 3
}

.down a {
    padding: 10px 5px;
    display: block
}

.down a:hover,
.down a.active {
    background: rgba(0, 0, 0, .2)
}

.sns {
    margin-top: 20px
}

.sns a:hover {
    opacity: .6
}

.side_nav {
    position: fixed;
    right: 0;
    bottom: 45%;
    z-index: 9
}

.side_nav .a2a_kit a {
    display: block;
    float: none;
    margin-bottom: 5px
}

.all-height {
    height: 100%
}

a.btn {
    padding: 15px;
    text-align: center;
    margin: 2px auto;
    display: inline-block;
    background: #f7e384;
    background: -moz-linear-gradient(top, rgba(247, 227, 132, 1) 0%, rgba(238, 196, 43, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(247, 227, 132, 1) 0%, rgba(238, 196, 43, 1) 100%);
    background: linear-gradient(to bottom, rgba(247, 227, 132, 1) 0%, rgba(238, 196, 43, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f7e384', endColorstr='#eec42b', GradientType=0);
    border-radius: 10px;
    color: #006dae !important
}

.down-btn {
    margin-top: -12%
}

footer {
    padding: 30px;
    color: #999;
    background-color: #000
}

footer a {
    color: var(--white)
}

.content {
    padding: 15px
}

.banner {
    color: #fff;
    background-image: url(/public/images/banner-bg.webp);
    /* background-image: url('/images/banner-bg.webp'); */
    /* background-image:url(/public/raw-html/img/banner-bg.webp); */
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    background-size: cover;
    padding: 220px 0 100px;
    overflow: hidden;
}

.banner a,
.w-white a {
    color: #fff
}

.banner-title {
    font-size: 3rem;
    display: inline-block;
    line-height: 1.2
}

.banner-block .btn {
    min-width: 30%;
    box-shadow: 5px 0 15px rgba(0, 0, 0, .4)
}

.img2 {
    margin-top: -20px
}

.sport-img1 {
    margin-bottom: -50px
}

.check-list li .content {
    background-color: #eee
}

.check-list li:hover .content {
    background-color: #006dae;
    color: #fff
}

.check-list li img:nth-child(2) {
    display: none
}

.check-list li:hover img:nth-child(1) {
    display: none
}

.check-list li:hover img:nth-child(2) {
    display: block
}

.home-games {
    padding: 20px 5%
}

.home-step .tt {
    font-size: 2em;
    border-bottom: 1px solid #0068b7;
    padding-bottom: 10px;
    font-style: italic
}

.sport-list {
    margin-right: -5%;
    margin-left: 5%;
    position: relative
}

.sport-list:before,
.sport-list::after {
    content: '';
    width: 200px;
    height: 200px;
    background-color: var(--secondary-color);
    position: absolute;
    -moz-transform-origin: bottom;
    -moz-transform: skewX(15deg);
    -webkit-transform-origin: bottom;
    -webkit-transform: skewX(15deg);
    -o-transform-origin: bottom;
    -o-transform: skewX(15deg);
    -ms-transform-origin: bottom;
    -ms-transform: skewX(15deg);
    transform-origin: bottom;
    transform: skewX(15deg)
}

.sport-list::before {
    right: -20px;
    bottom: -10px
}

.sport-list::after {
    left: -55px;
    top: -10px
}

.sport-list li .block {
    background-color: #2d0afc;
    overflow: hidden
}

.sport-list li {
    z-index: 2;
    -moz-transform-origin: bottom;
    -moz-transform: skewX(15deg);
    -webkit-transform-origin: bottom;
    -webkit-transform: skewX(15deg);
    -o-transform-origin: bottom;
    -o-transform: skewX(15deg);
    -ms-transform-origin: bottom;
    -ms-transform: skewX(15deg);
    transform-origin: bottom;
    transform: skewX(15deg)
}

.sport-list li img,
.sport-list li .content {
    -moz-transform-origin: bottom;
    -moz-transform: skewX(-15deg);
    -webkit-transform-origin: bottom;
    -webkit-transform: skewX(-15deg);
    -o-transform-origin: bottom;
    -o-transform: skewX(-15deg);
    -ms-transform-origin: bottom;
    -ms-transform: skewX(-15deg);
    transform-origin: bottom;
    transform: skewX(-15deg)
}

.sport-list li img {
    max-width: 130%;
    width: 130%;
    margin-left: -30%
}

.game-list li a {
    overflow: hidden;
    border-radius: 20px
}

.slot-list li .img_fit {
    margin-top: -50px
}

.home-bg1 {
    background: url(https://jiliko.ph/resource/images/home-bg.jpg) no-repeat left center
}

.home-bg2 {
    background: url(https://jiliko.ph/resource/images/home-bg2.jpg) no-repeat center
}

.home-bg3 {
    /* background: url(../images/home-bg3.jpg) no-repeat center */
}

.pagetop {
    background-color: #0a013b;
    background-position: center bottom;
    background-size: cover;
    padding: 230px 0 80px;
    text-align: center;
    color: #fff
}

.pagetop1 {
    /* background-image: url(../images/pagetop1.jpg) */
}

.pagetop2 {
    /* background-image: url(../images/pagetop2.jpg) */
}

.pagetop3 {
    /* background-image: url(../images/pagetop3.jpg) */
}

.pagetop4 {
    /* background-image: url(../images/pagetop4.jpg) */
}

.pagetop5 {
    /* background-image: url(../images/pagetop5.jpg) */
}

.pagetop6 {
    /* background-image: url(../images/pagetop6.jpg) */
}

.pagetop7 {
    /* background-image: url(../images/pagetop7.jpg) */
}

.pagetop8 {
    /* background-image: url(../images/pagetop8.jpg) */
}

.page-title {
    font-size: 3em;
    font-weight: 400;
    display: inline-block
}

.banner-title {
    font-size: 3em;
    font-weight: 400
}

.banner-title span {
    display: block
}

.join-bg {
    /* background-image: url(../images/join-bg.jpg) */
}

.slot-bg {
    /* background-image: url(../images/slot-bg.jpg) */
}

.casino-bg {
    /* background-image: url(../images/casino-bg.jpg) */
}

.sport-bg {
    /* background-image: url(../images/sport-bg.jpg) */
}

.fish-bg {
    /* background-image: url(../images/fish-bg.jpg) */
}

.sabong-bg {
    /* background-image: url(../images/sabong-bg.jpg) */
}

.arcade-bg {
    /* background-image: url(../images/arcade-bg.jpg) */
}

.promo-bg {
    /* background-image: url(../images/promo-bg.jpg) */
}

.main {
    padding-top: 40px;
    padding-bottom: 40px
}

.mask {
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    color: var(--white);
    padding: 10px 15px;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 99%, rgba(0, 0, 0, 0.65) 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 99%, rgba(0, 0, 0, 0.65) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 99%, rgba(0, 0, 0, 0.65) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0)
}

.title {
    line-height: 1.2;
    font-size: var(--h2)
}

.table-main {
    border-left: 1px solid #999;
    border-bottom: 1px solid #999;
    margin: 15px 0
}

.table-main th,
.table-main td {
    padding: 8px;
    border-right: 1px solid #999;
    border-top: 1px solid #999
}

.table-main th {
    background-color: #eee;
    color: #000;
    border-right: 1px solid #999
}

.border {
    border-radius: 8px
}

.p {
    margin: 15px 0
}

.spot li,
.w6 {
    /* background: url(../images/spot.png) no-repeat left 5px; */
    padding-left: 25px;
    margin: 5px 0
}

.spot-w li {
    /* background: url(../images/spot-w.png) no-repeat left 5px */
}

.spot {
    margin: 10px 0
}

:root {
    --primary-color: #ffd300;
    --secondary-color: #006dae;
    --white: #fff;
    --h2: 2rem
}

.h2 {
    font-size: var(--h2)
}

.bg-w {
    background-color: #fff
}

.bg-main,
#nav-icon span {
    background-color: var(--primary-color)
}

.w-main {
    color: var(--primary-color)
}

.w1 {
    color: var(--secondary-color)
}

.w-white {
    color: var(--white)
}

.bg {
    /* background: url(../images/bg.jpg) no-repeat center #000; */
    background-attachment: fixed
}

.bg-b {
    background-color: #01466f
}

.w-b {
    color: #000
}

.title,
.banner-title,
.tt,
.page-title {
    font-family: anton, sans-serif
}

.btn-b {
    background: #0068b7;
    color: #fff;
    border-radius: 20px;
    padding: 10px;
    width: 80%;
    display: block;
    margin: 10px auto
}

.btn-b:hover {
    background: #104d7b
}

.bg-grey {
    background-color: #eee
}

.hidden {
    overflow: hidden
}

footer,
nav a,
.center {
    text-align: center
}

.inline,
.pagenav a {
    display: inline-block
}

#nav-icon span,
.thumbimg {
    display: block
}

#nav-icon,
.down,
.hidden-md,
.hidden-lg {
    display: none
}

nav>a,
.inline {
    vertical-align: middle
}

.relate,
.thumbimg {
    position: relative
}

#nav-icon span,
.topnav li>a:after,
.mask,
.cover {
    position: absolute
}

.bg,
.bg-cover {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat
}

a {
    cursor: pointer
}

.btn,
.w-bold {
    font-weight: 700
}

.w2,
.tt {
    font-size: 1.5rem;
    line-height: 1.2
}

.w3 {
    font-size: 1.2rem
}

.num {
    font-size: 3.5rem;
    line-height: 1;
    display: inline-block
}

.mb-3 {
    margin-bottom: 2rem
}

.mb-2 {
    margin-bottom: 1.2rem
}

.mt-3 {
    margin-top: 2rem
}

.mt-2 {
    margin-top: 1.2rem
}

.py-3 {
    padding: 2rem 0
}

.l1 {
    line-height: 1.1
}

.pb-0 {
    padding-bottom: 0
}

.pt-0 {
    padding-top: 0
}

.pb-3 {
    padding-bottom: 2rem
}

.pt-3 {
    padding-top: 2rem
}

.pr-2 {
    padding-right: 1.2rem
}

.py-2 {
    padding: 20px 0
}

.p-2 {
    padding: 20px
}

.pt-0 {
    padding-top: 0
}

@media screen and (min-width:900px) and (max-width:1200px) {
    .container {
        width: 850px
    }

    .banner-img {
        margin-right: -20%
    }
}

@media screen and (min-width:700px) and (max-width:899px) {
    .container {
        width: 670px
    }
}

@media screen and (max-width:1200px) {
    .logo {
        max-width: 180px
    }

    .col-xl-9 {
        width: 66%
    }

    .col-xl-6 {
        width: 50%
    }

    .col-xl-12 {
        width: 100%
    }

    .pagetitle,
    .pagetop h2 {
        font-size: 2rem
    }
}

@media screen and (max-width:899px) {
    .col-lg-12 {
        width: 100%;
        padding: 0
    }

    .col-lg-6 {
        width: 50%;
        padding: 0
    }

    .col-lg-9 {
        width: 66.66%;
        padding: 0
    }

    .col-lg-3 {
        width: 33.33%;
        padding: 0
    }

    #nav-icon,
    .hidden-lg {
        display: block
    }

    .topnav li>a {
        padding: 10px 0;
        border-bottom: 1px dashed rgba(0, 0, 0, .2)
    }

    .topnav,
    .down,
    .topnav li>a::after {
        display: none
    }

    .topnav {
        overflow: auto
    }

    .topnav {
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        z-index: 999;
        padding-top: 50px;
        background: rgba(0, 0, 0, .9)
    }

    .topnav li {
        position: relative
    }

    .banner,
    .pagetop {
        padding: 150px 0 50px
    }
}

@media screen and (max-width:699px) {
    .container {
        width: 100%;
        padding: 0 20px
    }

    .main {
        padding-top: 20px;
        padding-bottom: 20px
    }

    nav a,
    .col-md-12 {
        width: 100% !important
    }

    .col-md-6 {
        width: 50%
    }

    .col-md-3 {
        width: 33.33%
    }

    .col-R.col-md-12,
    .col-L.col-md-12 {
        padding: 0
    }

    .hidden-md {
        display: block
    }

    .visible-md,
    .side_nav .a2a_kit {
        display: none
    }

    .title,
    .banner-title {
        font-size: 1.5rem;
        line-height: 1.3
    }

    .btn-list .btn .inline {
        display: block
    }

    .box .w2 {
        text-align: center
    }

    .check-list img {
        width: 50px;
        display: inline-block
    }

    .pb-md-0 {
        padding-bottom: 0
    }

    .side_nav {
        bottom: 0
    }
}

@media screen and (max-width:479px) {
    .col-sm-12 {
        width: 100%
    }

    .col-sm-12.col-R {
        padding: 0
    }

    .col-sm-6 {
        width: 50%
    }

    .col-sm-9 {
        width: 66.66%
    }
}

.section-nopage {
    min-height: 90vh;
}