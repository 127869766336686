.pagetop {
    background-color: #0a013b;
    background-position: center bottom;
    background-size: cover;
    padding: 230px 0 80px;
    text-align: center;
    color: #fff
}

.pagetop1 {
    background-image: url(https://jiliko.ph/resource/images/pagetop1.jpg)
}

.join-bg {
    background-image: url(https://jiliko.ph/resource/images/join-bg.jpg)
}
